import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Com el seu nom indica aquest bolet presenta un capell al principi i durant molt de temps com un ou ovoide cobert per un vel blanc que posteriorment s’obri quedant un capell convex de 10 a 20 cm de diàmetre. El marge pot dur restes membranosos- cotonosos del vel parcial que uneix el capell al peu i protegeix les làmines. Aquestes són blanques, gruixades, molt apretades i lliures del peu. El peu de 10-20 x 2-4 cm, robust, blanc, cilíndric i cobert d’una mena de farina que empastifa els dits quan es toca. El peu s’enfunda a la base en una amplia volva molt consistent, blanca o blanc-groguenca. L’anell fràgil és difícil d’observar complet perquè es romp amb facilitat. Les espores són blanques en massa, el·líptiques, de 10 x 7 micres i en quantitat tan gran que recorda la farina.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      